import { useEffect, useRef } from "react"
import { register } from "swiper/element/bundle"
import type { SwiperSlideProps, SwiperProps } from "swiper/react"

export function Swiper(props: SwiperProps & { onSwiperInit?: (swiper: any) => void }) {
  const swiperRef = useRef<any>(null)
  const { children, onSwiperInit, ...rest } = props

  useEffect(() => {
    register()

    const params = {
      ...rest,
    }

    if (swiperRef.current) {
      Object.assign(swiperRef.current, params)
      swiperRef.current.initialize()

      if (onSwiperInit) {
        onSwiperInit(swiperRef.current)
      }
    }
  }, [swiperRef.current])

  return (
    <swiper-container init={false} ref={swiperRef}>
      {children}
    </swiper-container>
  )
}

export function SwiperSlide(props: SwiperSlideProps) {
  const { children, ...rest } = props

  return <swiper-slide {...rest}>{children as React.ReactNode}</swiper-slide>
}
